import React from "react";
import Grid from "@mui/material/Grid";
import { PolicyTitle, PolicyText } from "../styles/classes";
import Layout from "../components/layout";

export default function TermOfUse() {
    return (
        <Layout>
            <Grid container spacing={2}>
                <Grid item sx={{ mt: 4 }}>
                    <PolicyTitle>Terms & Conditions</PolicyTitle>
                </Grid>
                <Grid item>
                    <PolicyText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vel bibendum quis cursus mi, metus, sit. Mattis
                        tincidunt turpis eleifend enim non nullam tincidunt
                        egestas sem. Arcu massa tellus massa gravida nec
                        iaculis. At id nulla posuere risus volutpat gravida.
                        Arcu viverra mi nunc lorem. Arcu est tellus amet, elit
                        mattis lacus consectetur. Dictum in sollicitudin magna
                        lobortis consequat. Urna, rutrum ultricies nulla diam
                        nisl at eget vitae. Vitae consequat, mattis risus at
                        cras iaculis.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel bibendum quis cursus mi, metus,
                        sit. Mattis tincidunt turpis eleifend enim non nullam
                        tincidunt egestas sem. Arcu massa tellus massa gravida
                        nec iaculis. At id nulla posuere risus volutpat gravida.
                        Arcu viverra mi nunc lorem. Arcu est tellus amet, elit
                        mattis lacus consectetur. Dictum in sollicitudin magna
                        lobortis consequat. Urna, rutrum ultricies nulla diam
                        nisl at eget vitae. Vitae consequat, mattis risus at
                        cras iaculis.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel bibendum quis cursus mi, metus,
                        sit. Mattis tincidunt turpis eleifend enim non nullam
                        tincidunt egestas sem.
                    </PolicyText>
                </Grid>

                <Grid item sx={{ mt: 4 }}>
                    <PolicyTitle>Cookies</PolicyTitle>
                </Grid>
                <Grid item>
                    <PolicyText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vel bibendum quis cursus mi, metus, sit. Mattis
                        tincidunt turpis eleifend enim non nullam tincidunt
                        egestas sem. Arcu massa tellus massa gravida nec
                        iaculis. At id nulla posuere risus volutpat gravida.
                        Arcu viverra mi nunc lorem. Arcu est tellus amet, elit
                        mattis lacus consectetur. Dictum in sollicitudin magna
                        lobortis consequat. Urna, rutrum ultricies nulla diam
                        nisl at eget vitae. Vitae consequat, mattis risus at
                        cras iaculis.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel bibendum quis cursus mi, metus,
                        sit. Mattis tincidunt turpis eleifend enim non nullam
                        tincidunt egestas sem. Arcu massa tellus massa gravida
                        nec iaculis. At id nulla posuere risus volutpat gravida.
                        Arcu viverra mi nunc lorem. Arcu est tellus amet, elit
                        mattis lacus consectetur. Dictum in sollicitudin magna
                        lobortis consequat. Urna, rutrum ultricies nulla diam
                        nisl at eget vitae. Vitae consequat, mattis risus at
                        cras iaculis.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel bibendum quis cursus mi, metus,
                        sit. Mattis tincidunt turpis eleifend enim non nullam
                        tincidunt egestas sem. Arcu massa tellus massa gravida
                        nec iaculis. At id nulla posuere risus volutpat gravida.
                        Arcu viverra mi nunc lorem. Arcu est tellus amet, elit
                        mattis lacus consectetur. Dictum in sollicitudin magna
                        lobortis consequat. Urna, rutrum ultricies nulla diam
                        nisl at eget vitae. Vitae consequat, mattis risus at
                        cras iaculis.
                    </PolicyText>
                </Grid>
                <Grid item sx={{ mt: 4 }}>
                    <PolicyTitle>License</PolicyTitle>
                </Grid>
                <Grid item>
                    <PolicyText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vel bibendum quis cursus mi, metus, sit. Mattis
                        tincidunt turpis eleifend enim non nullam tincidunt
                        egestas sem. Arcu massa tellus massa gravida nec
                        iaculis. At id nulla posuere risus volutpat gravida.
                        Arcu viverra mi nunc lorem. Arcu est tellus amet, elit
                        mattis lacus consectetur. Dictum in sollicitudin magna
                        lobortis consequat. Urna, rutrum ultricies nulla diam
                        nisl at eget vitae. Vitae consequat, mattis risus at
                        cras iaculis.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel bibendum quis cursus mi, metus,
                        sit. Mattis tincidunt turpis eleifend enim non nullam
                        tincidunt egestas sem. Arcu massa tellus massa gravida
                        nec iaculis. At id nulla posuere risus volutpat gravida.
                        Arcu viverra mi nunc lorem. Arcu est tellus amet, elit
                        mattis lacus consectetur. Dictum in sollicitudin magna
                        lobortis consequat. Urna, rutrum ultricies nulla diam
                        nisl at eget vitae. Vitae consequat, mattis risus at
                        cras iaculis.Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Vel bibendum quis cursus mi, metus,
                        sit. Mattis tincidunt turpis eleifend enim non nullam
                        tincidunt egestas sem. Arcu massa tellus massa gravida
                        nec iaculis. At id nulla posuere risus volutpat gravida.
                        Arcu viverra mi nunc lorem. Arcu est tellus amet, elit
                        mattis lacus consectetur. Dictum in sollicitudin magna
                        lobortis consequat. Urna, rutrum ultricies nulla diam
                        nisl at eget vitae. Vitae consequat, mattis risus at
                        cras iaculis.
                    </PolicyText>
                </Grid>
            </Grid>
        </Layout>
    );
}
